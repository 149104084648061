var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-carousel',{staticClass:"carousel background lighten-3",attrs:{"cycle":true,"delimiter-icon":"mdi-circle","hide-delimiter-background":"","height":"100%","light":"","show-arrows":false}},_vm._l((_vm.items),function({ title, subtitle },i){return _c('v-carousel-item',{key:i},[_c('v-img',{attrs:{"src":require(`@/assets/images/auth/carousel-item-${i + 1}.png`),"height":"344","width":"344"}}),_c('h1',{staticClass:"font-weight-medium secondary--text text-h4 text-lg-h3"},[_vm._v(" "+_vm._s(title)+" ")]),_c('p',{class:[
        'font-weight-regular',
        'secondary--text',
        'text--lighten-1',
        'text-lg-h6',
        'text-center',
        'mb-0',
      ]},[_vm._v(" "+_vm._s(subtitle)+" ")])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }